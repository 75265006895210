const contacts = {
  phones: [
    {
      href: "tel:+77054095223",
      value: "+7 705 409 52 23"
    },
  ],
  address: {
    city: "Almaty",
    street: "Mailina, 85/2",
    longitude: '',
    latitude: ''
  },
  workHours: [
    "Mon-Fri: 9:00 - 18:00",
    "Sat-Sun: by appointment"
  ]
};

export default contacts;