import * as React from "react";
import PropTypes from "prop-types";
import price from "../../../lib/price";

function PriceItem({title, price, priceKey}) {
  return (
    <div className="price_item">
      <span className="label">DPF Cleaning</span>
      <span className="title">{title}</span>
      <span className="price">{price.toLocaleString("ru")} KZT</span>
      <span className="price_description">* the price is for cleaning the removed particulate filter and DOES NOT CHANGE depending on the degree of contamination</span>
    </div>
  )
}

PriceItem.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  priceKey: PropTypes.oneOf(Object.keys(price)).isRequired
}

export default PriceItem;