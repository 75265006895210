const price = {
  car: {
    title: "Passenger car",
    price: 80000
  },
  van: {
    title: "Commercial transport / minibus / minivan",
    price: 100000
  },
  tir: {
    title: "Cargo transport / TIR",
    price: 190000
  }
};

export default price;