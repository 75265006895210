import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import YoutubeIframe from "./YoutubeIframe";


function Youtube() {
  return (
    <Row>
      <Container>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6} xl={4}>
            <YoutubeIframe src="https://www.youtube.com/embed/v25CztCiFcA"/>
          </Col>

          <Col xs={12} sm={12} md={6} lg={6} xl={4}>
            <YoutubeIframe src="https://www.youtube.com/embed/KuNXhwa1nvU"/>
          </Col>

          <Col xs={12} sm={12} md={6} lg={6} xl={4}>
            <YoutubeIframe src="https://www.youtube.com/embed/zlGWu-qXG0E"/>
          </Col>

          <Col xs={12} sm={12} md={6} lg={6} xl={4}>
            <YoutubeIframe src="https://www.youtube.com/embed/H0LlhWZPGfk"/>
          </Col>

          <Col xs={12} sm={12} md={6} lg={6} xl={4}>
            <YoutubeIframe src="https://www.youtube.com/embed/f6FxmI9DoUI"/>
          </Col>

          <Col xs={12} sm={12} md={6} lg={6} xl={4}>
            <YoutubeIframe src="https://www.youtube.com/embed/xaix-jkj4qo"/>
          </Col>
        </Row>
      </Container>
    </Row>
  )
}

export default Youtube;